<template>
  <router-view/>
</template>

<style lang="less">
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
