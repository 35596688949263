import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import element from 'element-plus';
import 'element-plus/theme-chalk/index.css'

import VideoPlayer from 'vue-video-player/src'
import 'video.js/dist/video-js.css' 
import 'vue-video-player/src/custom-theme.css'

createApp(App).use(store).use(router).use(element).use(VideoPlayer).mount('#app')
