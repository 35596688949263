<template>
  <div id="aplayer" ref="aplayer" ></div>
</template>
<script setup>
import "aplayer/dist/APlayer.min.css";
import APlayer from "aplayer";
import { onMounted, ref } from "@vue/runtime-core";

const props = defineProps({
  // 开启吸底模式
  fixed: {
    type: Boolean,
    default: false,
  },
  // 开启迷你模式
  mini: {
    type: Boolean,
    default: false,
  },
  // 音频自动播放
  autoplay: {
    type: Boolean,
    default: false,
  },
  // 主题色
  theme: {
    type: String,
    default: "rgba(255,255,255,0.2)",
  },
  // 音频循环播放
  loop: {
    type: String,
    default: "all", //'all' | 'one' | 'none'
  },
  // 音频循环顺序
  order: {
    type: String,
    default: "random", //'list' | 'random'
  },
  // 预加载
  preload: {
    type: String,
    default: "auto", //'auto' | 'metadata' | 'none'
  },
  // 默认音量
  volume: {
    type: Number,
    default: 0.7,
    validator: (value) => {
      return value >= 0 && value <= 1;
    },
  },
  // 歌曲服务器(netease-网易云, tencent-qq音乐, kugou-酷狗, xiami-小米音乐, baidu-百度音乐)
  songServer: {
    type: String,
    default: "netease", //'netease' | 'tencent' | 'kugou' | 'xiami' | 'baidu'
  },
  // 播放类型(song-歌曲, playlist-播放列表, album-专辑, search-搜索, artist-艺术家)
  songType: {
    type: String,
    default: "playlist",
  },
  // 歌的id
  songId: {
    type: String,
    default: "19723756",
  },
  // 互斥，阻止多个播放器同时播放，当前播放器播放时暂停其他播放器
  mutex: {
    type: Boolean,
    default: true,
  },
  // 传递歌词方式
  lrcType: {
    type: Number,
    default: 1,
  },
  // 列表是否默认折叠
  listFolded: {
    type: Boolean,
    default: false,
  },
  // 列表最大高度
  listMaxHeight: {
    type: String,
    default: "1000px",
  },
  // 存储播放器设置的 localStorage key
  storageName: {
    type: String,
    default: "aplayer-setting",
  },
});

const aplayer = ref();
const lrc = '[00:00.000] 作词 : MIMI[00:01.000] 作曲 : MIMI[00:12.00][00:15.00]続く　時間の欠片[00:20.00]を集めている　ただ[00:23.00]過ぎるノートの余白に書く[00:28.00]「答えは、いつ？」[00:29.00][00:30.00]ほんのり小さな感情へ[00:32.00][00:34.00]愛をひとつまたねまたね[00:37.00]夜に咲く温度と灯るまで[00:41.00]呼吸　ひとつ生きる生きる[00:45.00]優しい日々の横で泣かぬように　嗚呼[00:50.00][00:55.00]泣かぬように[00:59.00][01:03.00]ちっちゃな言葉チクチクしたの[01:09.00]キリが無いけどさ[01:11.00]それを忘れたフリ疲れたよ[01:16.00]夜よ抱きしめて[01:25.00][01:34.00]今日だって　笑う笑う[01:36.00]泣いちゃう僕を隠すために笑う[01:40.00]言えないことは言えないで良い[01:44.00]って思えたら軽くなれるのかな[01:46.00][01:48.00]愛をひとつまたねまたね[01:51.00]夜に咲く温度と灯るまで[01:55.00]呼吸　ひとつ生きる生きる[01:59.00]優しい日々の横で泣かぬように[02:01.00][02:03.00]愛をひとつまたねまたね[02:06.00]嗚呼[02:10.00]愛をひとつまたねまたね[02:13.00]優しい日々の横で笑うように[02:17.00]嗚呼[02:18.00][02:18.00]吐き出す気持ちは簡単で[02:19.00]でも生きるの難易度高すぎて[02:21.00]どうにも解けない問いだらけ[02:24.00]嗚呼さっぱりきっかり前向いて[02:27.00]なんてしなくて良いから抱きしめて[02:29.00]愛をひとつまたねまたね'

const audioList = [
  {
    name: "三打白骨精",
    artist: "吉美",
    url: " https://alihls.jm-kid.com/customerTrans/6b758d50446f4df668663680a4deabf1/45db3599-17195af24a0-0006-4f38-8d7-457bc.mp3",
    //lrc:lrc,
    cover:  require('../assets/img/x1.jpg'),
  },
  {
    name: "真假美猴王（上）",
    artist: "吉美",
    url: " https://alihls.jm-kid.com/customerTrans/6b758d50446f4df668663680a4deabf1/37cddd70-17195b33f72-0006-4f38-8d7-457bc.mp3",
    cover: require('../assets/img/x2.jpg'),
  },
  {
    name: "降伏纵火红孩儿 (下)",
    artist: "吉美",
    url: " https://alihls.jm-kid.com/customerTrans/6b758d50446f4df668663680a4deabf1/3e0cac7e-17195b8940f-0006-4f38-8d7-457bc.mp3",
    cover:  require('../assets/img/x3.jpg'),
  },
  {
    name: "三打白骨精（上）",
    artist: "吉美",
    url: " https://alihls.jm-kid.com/customerTrans/6b758d50446f4df668663680a4deabf1/45db3599-17195af24a0-0006-4f38-8d7-457bc.mp3",
    //lrc:lrc,
    cover:  require('../assets/img/x1.jpg'),
  },
  {
    name: "真假美猴王（下）",
    artist: "吉美",
    url: " https://alihls.jm-kid.com/customerTrans/6b758d50446f4df668663680a4deabf1/37cddd70-17195b33f72-0006-4f38-8d7-457bc.mp3",
    cover: require('../assets/img/x2.jpg'),
  },
  {
    name: "降伏纵火红孩儿 (上)",
    artist: "吉美",
    url: " https://alihls.jm-kid.com/customerTrans/6b758d50446f4df668663680a4deabf1/3e0cac7e-17195b8940f-0006-4f38-8d7-457bc.mp3",
    cover:  require('../assets/img/x3.jpg'),
  },
  {
    name: "降伏纵火红孩儿 ",
    artist: "吉美",
    url: " https://alihls.jm-kid.com/customerTrans/6b758d50446f4df668663680a4deabf1/3e0cac7e-17195b8940f-0006-4f38-8d7-457bc.mp3",
    cover:  require('../assets/img/x3.jpg'),
  },
];

onMounted(() => {
  const ap = new APlayer({
    container: aplayer.value,
    fixed: props.fixed,
    mini: props.mini,
    autoplay: props.autoplay,
    theme: props.theme,
    loop: props.loop,
    order: props.order,
    preload: props.preload,
    volume: props.volume,
    mutex: props.mutex,
    lrcType: props.lrcType,
    listFolded: props.listFolded,
    listMaxHeight: props.listMaxHeight,
    storageName: props.storageName,
    audio: audioList,
  });
});

</script>
<style scoped lang='less'>
// /deep/ .aplayer-body{
//   background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
// }
#aplayer{
     /* background-image: url('../assets/img/1.jpg'); */ 
     width: 80%;
     margin: 0 auto;
      /deep/ .aplayer-info{
      background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
      .aplayer-lrc{
        background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
        &::before{
         background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
        }
        &::after{
          background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
        }
      }
     }
     /deep/ .aplayer-list{
      li{
        height: 3vw;
        line-height: 3vw;
        background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
        &:hover{
         background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
        }
      }
     }
}
</style>
