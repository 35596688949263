<template>
  <div class="footer">
    <div class="container">
      <p>版权所有©2022</p>
      <p>深圳市吉幼美教育科技有限公司</p>
      <a href="https://beian.miit.gov.cn"><p>粤ICP备2022061124号-1</p></a>
      <p>传真：0755-86593615</p>
      <p>服务热线：0755-86593615</p>
      <p>邮箱：denghywin@dingtalk.com</p>
      <p>公司地址：深圳市南山区科苑路8号讯美科技广场2号楼1308</p>
      <div style="width: 300px; margin: 0 auto;padding:30px 0;">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009081"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="" style="float: left" />
          <div class="content">
            <div class="icon"></div>
            <div
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
              "
            >
              粤公网安备 44030502009081号
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
a {
  text-decoration: none;
  color: #000;
}
.footer {
  width: 100vw;
  overflow: hidden;
  height: 18vw;
  padding: 3vw 5vw;
  background-image: linear-gradient(to top, #feada6 0%, #f5efef 100%);
  .container {
    width: 35%;
    margin: 0 auto;
    // text-align: center;
    div {
      a {
        position: relative;
        .content{
 display: flex;
        .icon {
          background-image: url("../assets/img/beian.png");
          background-size: 100%;
          width: 20px;
          height: 20px;
        }
        }
       
      }
    }
  }
}
</style>
