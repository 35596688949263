<template>
  <div class="home">
    <div class="container">
      <Header :isActive="0"></Header>
      <div class="baner"></div>
      <div class="slogan">
        <div class="core">
          <transition
            name="el-zoom-in-center"
            v-for="item3 in core"
            :key="item3"
          >
            <li v-show="show">
              {{ item3 }}
            </li>
          </transition>
        </div>
        <transition name="el-zoom-in-top">
          <div  class="detail">
            <div class="detail-left" v-show="show2">
              <!-- <div class="cell" v-for="item5 in cellList1" :key="item5" :style="{'backgroundImage':'url(' + item5.img + ')'}"></div> -->
            </div>
            <div class="detail-right" v-show="show2">
                <!-- <div class="cell" v-for="item6 in cellList1" :key="item6" :style="{'backgroundImage':'url(' + item6.img + ')'}"></div> -->
            </div>
          </div>
        </transition>
      </div>
      <div class="flash">
        <div class="title"><div class="icon"></div>有声绘本</div>
        <div class="canvas">
          <div class="canvas-swaper">
            <li
              v-for="item4 in canvasCover"
              :key="item4"
              :style="{ backgroundImage: 'url(' + item4.img + ')' }"
            >
            <div class="coverMask">
              <a :href="item4.url"><i class = 'iconfont icon-lianjie'></i></a>
            </div>
            </li>
          </div>
          <div class="canvas-intro">
            <div class="intro-top">
              <div class="sl">浸入式有声阅读氛围</div>
              <div class="sr">1-7岁绘本分龄阅读</div>
            </div>
            <div class="intro-center">
              <div
                class="point"
                v-for="item5 in point"
                :key="item5"
                :style="{ backgroundColor: item5.color }"
              >
                {{ item5.name }}
              </div>
            </div>
            <div class="intro-bottom">
              {{
                "阅读计划，教育专家制定阅读计划内容，让孩子在科学理念中培养和学习，交互绘本阅读内容，玩乐体验中学习。手指点触绘本中人物、动物、场景等进行互动，全面提升宝宝阅读体验"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="kan-study">
        <div class="kan">
          <div class="title"><div class="icon"></div>看学</div>
          <div class="card-pine">
            <!-- <div class="card">
              <div class="maskShow">
                <i class="iconfont icon-jinrudaobo" @click="this.$router.push('/video')"></i>
              </div>
            </div> -->
            <div class="video">
              <VPlayer
              :video="'https://alihls.jm-kid.com/912c5bc16b854da797f31fa60f8bcf34/d16e91cd9ee0d48a31797acaa76985fb-sd.mp4'"
              :cover="'https://alihls.jm-kid.com/912c5bc16b854da797f31fa60f8bcf34/snapshots/fa42bc1990014662be27dd4d8782389f-00005.jpg'"
            ></VPlayer>
            </div>
            <div class="card-intro">
                <!-- <div class="intro-card" v-for="item7 in kanImg" :key="item7">
                  <div class="intro-img"  :style="{ backgroundImage: 'url(' + item7.img + ')'}"></div>
                  <div class="intro-centent">{{item7.content}}</div>
                </div> -->
              <div class="card">
                <div class="pictrue">

                </div>
                <div class="card-title">
                  看学简介
                </div>
                <div class="txt">
                  趣味的卡通动画，是宝宝的最爱；在看动画的时候，耳濡目染学儿歌、学国学、学英语；
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="ting">
          <div class="title">听学</div>
          <div class="card-pine">
            <div class="card">
              <div class="maskShow">
                <i class="iconfont icon-jinrudaobo" @click="this.$router.push('/audio')"></i>
              </div>
            </div>
            <div class="card-intro">
              <div class="top">聆听故事，启蒙成长</div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="ting-study">
        <div class="ting">
          <div class="title"><div class="icon"></div>听学</div>
          <div class="ting-pine">
            <div class="ting-left">
              <div class="card">
                <div class="pictrue">

                </div>
                <div class="card-title">
                  听学简介
                </div>
                <div class="txt">
                  这里有宝宝喜欢的儿歌、故事，专业的配音配乐、录制、编辑团队，制作高质量内容
                </div>
              </div>
            </div>
            <div class="ting-right">
              <APlayer :theme='"rgba(0,0,0,0.6)"' :listMaxHeight = '"100%"'></APlayer>
            </div>
          </div>
        </div>
      </div>
      <!-- <APlayer></APlayer> -->
      <!-- <VPlayer :video="'https://alihls.jm-kid.com/912c5bc16b854da797f31fa60f8bcf34/d16e91cd9ee0d48a31797acaa76985fb-sd.mp4'"
    :cover ="'https://alihls.jm-kid.com/912c5bc16b854da797f31fa60f8bcf34/snapshots/fa42bc1990014662be27dd4d8782389f-00005.jpg'"></VPlayer> -->
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import APlayer from "@/components/aplayer.vue";
import VPlayer from "@/components/videoPlayer.vue";
import "../assets/font/iconfont.css";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
    APlayer,
    VPlayer,
  },
  data() {
    return {
      core: ["好阅读", "好兴趣", "好未来"],
      
      show: false,
      show2:false,
      canvasCover: [
        {
          img: require("../assets/img/c2.jpg"),
          url:'http://html5.jm-kid.com/flash/1929/page/0',
        },
        {
          img: require("../assets/img/c1.jpg"),
          url:'http://html5.jm-kid.com/flash/1928/page/0'
        },
        // {
        //   img: require("../assets/img/canvas3.jpg"),
        // },
      ],
      open: 0,
      point: [
        {
          name: "点",
          color: "rgb(253,119,133)",
        },
        {
          name: "看",
          color: "rgb(251,157,105)",
        },
        {
          name: "听",
          color: "rgb(90,196,186)",
        },
        {
          name: "说",
          color: "rgb(162,200,91)",
        },
        {
          name: "想",
          color: "#6f86d6",
        },
      ],
      
    };
  },
  methods: {
    doSwitch(i) {
      this.isActive = i;
    },
  },
  created() {
    window.onscroll = () => {
      if (window.pageYOffset > 200) {
        this.show = true;
      }
      if(window.pageYOffset > 360){
        this.show2 = true
      }
    };
  },
};
</script>

<style scoped lang="less">
li {
  list-style: none;
}
a{
  text-decoration: none;
}
.home {
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  .container {
    // background-image: linear-gradient(to top, #96fbc4 0%, #f9f586 100%);
    background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
    width: 100%;
    overflow: hidden;
    .baner {
      border-radius: 20px;
      background-image: url("../assets/img/baner2.jpg");
      width: 100%;
      height: 40vw;
      background-size: 100%,100%;
      background-repeat: no-repeat;
    }
    .slogan {
      //margin-top: 1.5vw;
      width: 100%;
      //height: 32vw;
      border-radius: 20px;
      background-image: linear-gradient(60deg, #abecd6 0%, #fbed96 100%);
      padding: 10vw 0;
      .core {
        height: 10vw;
        width: 55vw;
        display: flex;
        margin: 0 auto;
        li {
          width: 15vw;
          height: 10vw;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          color: #fff;
          font-size: 40px;
          +li{
             margin-left: 5vw;
          }
        }
        li:nth-child(1) {
          background-color: rgb(251, 157, 105);
        }
        li:nth-child(2) {
          background-color: rgb(126, 196, 186);
        }
        li:nth-child(3) {
          background-color: rgb(162, 200, 91);
        }
      }
      .detail {
        width: 55vw;
        height: 12vw;
        margin: 0 auto;
        border-radius: 20px;
        margin-top: 3vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .detail-left{
          height: 100%;
          width: 48%;
          display: flex;
          flex-wrap: wrap;
          //border-bottom: 1px solid #ddd;
          background-image: url('../assets/img/s1.jpg');
          border-radius: 20px;
           background-size: 100%,100%;
           background-repeat: no-repeat;
          .cell{
            width: 48%;
            height: 50%;
             //border-left: 1px solid #ddd;
            //border-top: 1px solid #ddd;
            background-size: 100%,100%;
          }
        }
        .detail-right{
          height: 100%;
          width: 50%;
           display: flex;
          flex-wrap: wrap;
          //border-bottom: 1px solid #ddd;
          background-image: url('../assets/img/s2.jpg');
          border-radius: 20px;
          background-size: 100%,100%;
           background-repeat: no-repeat;
          .cell{
             width: 50%;
            height: 50%;
            //border-left: 1px solid #ddd;
            //border-top: 1px solid #ddd;
            background-size: 100%,100%;
          }
        }
      }
    }
    .flash {
      width: 100%;
      height: 50vw;
      border-radius: 20px;
      //margin-top: 1.5vw;
      background-image: linear-gradient(to top, #fddb92 0%, #d1fdff 100%);
      padding: 5vw 5vw;
      .title {
        width: 18vw;
        font-size: 3vw;
        margin: 0 auto;
        display: flex;
        align-items: center;
        color: hsl(234, 50%, 54%);
        font-weight: 600;
        .icon{
          background-image: url('../assets/img/books.png');
          width: 6vw;
          height: 6vw;
          background-size: 100%,100%;
        }
      }
      .canvas {
        margin-top: 5vw;
        display: flex;
        justify-content: space-between;
        .canvas-swaper {
          width: 45vw;
          height: 25vw;
          display: flex;
          li {
            height: 100%;
            width: 25vw;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            border: 1px solid #ddd;
            margin-right: 1vw;
            box-shadow: 0 0 6px #ede3ef;
            &:hover{
              box-shadow: 0 0 12px #ede3ef;
              .coverMask{
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                display: flex;
                align-items: center;
                justify-content: center;
                i{
                  font-size:5vw;
                  color: #fff;
                }
              }
            }
          }
          li.open {
            width: 33vw;
          }
        }
        .canvas-intro {
          width: 45vw;
          height: 25vw;
          //border: 1px solid #009efd;
          padding: 2vw 5vw;
          .intro-top {
            display: flex;
            justify-content: space-between;
            padding: 0 2vw;
            font-weight: 600;
          }
          .intro-center {
            display: flex;
            justify-content: space-around;
            margin-top: 4vw;
            .point {
              width: 5.5vw;
              height: 5.5vw;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.5vw;
              font-weight: 600;
            }
          }
          .intro-bottom {
            margin-top: 6vw;
            font-size: 0.9vw;
          }
        }
      }
    }
    .kan-study {
      width: 100%;
      height: 50vw;
      //border: #96fbc4 1px solid;
      background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
      border-radius: 20px;
      .kan {
        width: 100%;
        height: 100%;
        padding:2.5vw 0;
        //border: #000 1px solid;
        .title {
          width: 18vw;
          //margin-bottom: 2vw;
          font-size: 3vw;
          text-align: center;
          margin: 0 auto;
          display: flex;
          align-items: center;
          font-weight: 600;
          color: #45ad8b;
          .icon{
          background-image: url('../assets/img/stationary-jar.png');
          width: 6vw;
          height: 6vw;
          background-size: 100%,100%;
        }
        }
        .card-pine {
          width: 100%;
          //display: flex;
          margin-top: 5vw;
          display: flex;
          padding: 0 4vw;
          justify-content: space-around;
         
          .card-intro {
            width: 40%;
            //margin-left: 2vw;
             .card{
            .pictrue{
              width: 25vw;
              height: 19vw;
              //border: #96fbc4 1px solid;
              background-image: url('../assets/img/kanxue.jpg');
              background-repeat: no-repeat;
              background-size: 100%,100%;
              border-radius: 8px;
            }
            .card-title{
              margin-top: 1.5vw;
              font-size: 1.5vw;
            }
            .txt{
              margin-top: 2vw;
              font-size: 1vw;
            }
          }
            
          }
        }
      }
      
    }
    .ting-study {
      width: 100%;
      height: 50vw;
      padding: 3vw 5vw;
      .ting {
        width: 100%;
        height: 50vw;
        .title {
        font-size: 3vw;
        width: 18vw;
        text-align: center;
        margin: 0 auto;
        font-weight: 600;
        display: flex;
        align-items: center;
        color: #d64e20;
        .icon{
          background-image: url('../assets/img/trumpet.png');
          width: 6vw;
          height: 6vw;
          background-size: 100%,100%;
        }
      }
      .ting-pine{
        margin-top: 4vw;
        height: 85%;
        display: flex;
        justify-content: start;
        padding: 1.5vw 5vw;
        //border: #96fbc4 1px solid;
        .ting-left {
          width: 35%;
          height: 100%;
          //border: #96fbc4 1px solid;
          .card{
            .pictrue{
              width: 20vw;
              height: 20vw;
              //border: #96fbc4 1px solid;
              background-image: url('../assets/img/xiyou.jpg');
              background-repeat: no-repeat;
              background-size: 100%,100%;
              border-radius: 8px;
            }
            .card-title{
              margin-top: 1.5vw;
              font-size: 1.5vw;
            }
            .txt{
              margin-top: 2vw;
              font-size: 1vw;
            }
          }
        }
        .ting-right {
          width: 65%;
          height: 100%;
          //border: #96fbc4 1px solid;
        }
      }
        
      }
    }
  }
}
</style>
