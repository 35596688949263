<template>
  <div class="header">
    <div class="nav-bar">
      <div class="logo">
        <img :src="logo.src" alt="" />
      </div>
      <div class="nav-list">
        <div
          class="nav-item"
          v-for="(item, index) in navList"
          :key="item"
          @click="doSwitch(index, item)"
        >
          <div class="pine">
            <div
              :class="[
                'icon',
                'iconfont',
                item.icon,
                isActive == index ? 'on' : '',
              ]"
            ></div>
            <div class="txt">{{ item.name }}</div>
          </div>
          <!-- <div class="drow-list">
            <div
              class="drow-item"
              v-for="item2 in drowList[index]"
              :key="item2"
            >
              {{ item2.name }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../assets/font/iconfont.css";
export default {
  props: ["isActive"],
  data() {
    return {
      logo: {
        src: require("../assets/img/logo_jym.png"),
      },
      navList: [
        {
          name: "首页",
          icon: "icon-shouye",
          path: "/",
        },
        {
          name: "关于我们",
          icon: "icon-heart",
          path: "/about",
        },
        {
          name: "登录",
          icon: "icon-denglu",
          path: "/login",
        },
        {
          name: "注册",
          icon: "icon-wozhucede",
          path: "/register",
        },
        // {
        //   name: "下载中心",
        //   icon: "icon-xiazaizhongxin",
        // },
      ],
      //  drowList: [
      //   [
      //     {
      //       name: " ",
      //     },
      //     {
      //       name: " ",
      //     },
      //   ],
      //   [
      //     {
      //       name: " ",
      //     },
      //   ],
      //   [
      //     {
      //       name: " ",
      //     },
      //   ],
      // ],
    };
  },
  methods: {
    doSwitch(i, data) {
      // this.isActive = i;
      this.$router.push(data.path);
    },
  },
};
</script>
<style scoped lang="less">
.header {
  background-image: linear-gradient(
    45deg,
    #ff9a9e 0%,
    #fad0c4 99%,
    #fad0c4 100%
  );
  width: 100vw;
  overflow: hidden;
  height: 20vh;

  .nav-bar {
    width: 75%;
    height: 80%;
    margin: 0 auto;
    padding-right: 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      img {
        height: 2vw;
        width: 13.4vw;
      }
    }
    .nav-list {
      display: flex;
      align-items: center;
      // background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
      // background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
      border-radius: 4px;
      .nav-item {
        padding: 0 2vw;
        height: 5vw;
        color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        .pine {
          text-align: center;
          .icon {
            font-size: 2vw;
            margin-bottom: 0.2vw;
            color: #fff;
          }
          .txt {
            height: 2vw;
          }
          .icon.on {
            color: #ff9a9e;
          }
          &:hover {
            //color: #ff9a9e;
          }
        }

        .drow-list {
          display: none;
          position: absolute;
          top: 5vw;
          left: 0;
        }
        &:hover {
          // background-image: linear-gradient(to top, #37ecba 0%, #72afd3 100%);
          // background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
          .icon {
            color: #ff9a9e;
          }
          .drow-list {
            height: 3vw;
            display: flex;
            align-items: center;
            justify-content: start;
            // background-image: linear-gradient(to top, #37ecba 0%, #72afd3 100%);
            background-image: linear-gradient(
              to top,
              #ff9a9e 0%,
              #fecfef 99%,
              #fecfef 100%
            );
          }
          .drow-item {
            padding: 0 2vw;
            height: 100%;
            display: flex;
            align-items: center;
            &:hover {
              background-image: linear-gradient(
                to top,
                #fad0c4 0%,
                #ffd1ff 100%
              );
            }
          }
        }
      }
    }
  }
}
</style>
